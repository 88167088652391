import { BackgroundGradient } from "./BackgroundGradient";
import Header from "./Header";

interface Props {
    children: React.ReactNode;
}

const Layout = ({children} : Props) => {
    return (
        <>
            <Header/>
            <div className='bg-slate-950 min-h-[100vh] pt-20'>
                <div className='absolute inset-x-0 top-[-10rem] transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]'>
                    <BackgroundGradient />
                </div>
                <div className="p-2">
                {children}
                </div>
            </div>
        </>
    )
}

export default Layout;