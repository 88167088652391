import React from 'react';
import Layout from './layout';
import SwapSection from 'page/swap';
import { BrowserRouter } from 'react-router-dom'
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { IS_MAINNET } from 'constant';
import { supportChains, testSupportChains } from 'constant/chains';
import { getDefaultWallets, RainbowKitProvider, midnightTheme } from '@rainbow-me/rainbowkit';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import './App.css';
import '@rainbow-me/rainbowkit/styles.css';
import 'react-toastify/dist/ReactToastify.css';


const { chains, provider } = configureChains(
  IS_MAINNET ? supportChains : [...testSupportChains, ...supportChains],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  chains,
  appName: 'IotaOrigin',
});

const wagmiClient = createClient({
  provider,
  connectors,
  autoConnect: true,
});

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={midnightTheme({
          accentColor: '#181818',
          accentColorForeground: 'white',
        })}
        modalSize='compact'
      >
        <BrowserRouter>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY!}
            language="en"
            useRecaptchaNet={true}
            useEnterprise={false}
            container={{
              parameters: {
                theme: 'dark',
              },
            }}
          >
            <Layout>
              <SwapSection/>
            </Layout>
          </GoogleReCaptchaProvider>
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
