import { Chain } from 'wagmi';
import { celo, celoAlfajores } from '@wagmi/core/chains';
import { IS_MAINNET } from '.';

export const CELO = 42220;
export const CELO_ALFAJORES = 44787;
export const SHIMMER_TESTNET = 1071;

// shimmer Beta chain
const shimmerTestnetChain: Chain = {
  id: SHIMMER_TESTNET,
  name: 'Shimmer Testnet',
  network: 'ShimmerTestnet',

  nativeCurrency: {
    name: 'SMR',
    symbol: 'SMR',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://json-rpc.evm.testnet.shimmer.network/'],
    },
    public: {
      http: ['https://json-rpc.evm.testnet.shimmer.network/'],
    },
  },
  blockExplorers: {
    default: {
      url: 'https://explorer.evm.testnet.shimmer.network',
      name: 'ShimmerScan',
    },
    etherscan: {
      url: 'https://explorer.evm.testnet.shimmer.network',
      name: 'ShimmerScan',
    },
  },
  testnet: true,
};

export const supportChains = [celo];
export const testSupportChains = [shimmerTestnetChain, celoAlfajores];

export const isSupportChain = (chainId: number) => {
  return IS_MAINNET
    ? chainId === CELO
    : chainId === CELO ||
        chainId === CELO_ALFAJORES ||
        chainId === SHIMMER_TESTNET;
};
