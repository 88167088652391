import { useCallback } from 'react';
import { erc20ABI, useContract, useSigner } from 'wagmi';
import { SNIP_ADDRESS } from 'constant';
import { BigNumber } from 'ethers';

interface PropsTypes {
  to: string;
  SnipAmount: BigNumber;
}

export const useSnipTransfer = ({
  to,
  SnipAmount
}: PropsTypes) => {
  const { data: signer } = useSigner();

    const contract = useContract({
        abi: erc20ABI,
        address: SNIP_ADDRESS,
        signerOrProvider: signer,
    });

    const sendSnip = useCallback(async () => {
      return await contract?.transfer(to as `0x${string}` , SnipAmount);
    }, [contract, to, SnipAmount]);

    return {
      sendSnip
    };

}