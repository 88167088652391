import { useState } from 'react';

interface PropsType {
    className?: string;
    inputClassName?: string;
    readOnly?: boolean;
    priceValue?: string;
    placeholder?: string;
    onChangePrice?: (val: string) => void;
    onChange?: (e: any) => void;
}

const Input = ({
    className,
    priceValue,
    readOnly=false,
    inputClassName,
    placeholder="0",
    onChangePrice,
    onChange
}: PropsType) => {
    const [isFocus, setFocus] = useState<boolean>(false);
    
    return (
        <div className={`${className}   ${isFocus ? "!border-gray-600" : "!border-[#131A2A]"} h-[100px] relative border border-gray-600 rounded-xl text-white bg-[#131A2A] p-4`}>
            <input
                className={`${inputClassName} bg-[#131A2A] outline-none text-4xl w-full h-full`}
                onFocus={(e) => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={(e) => {
                    onChange && onChange(e)
                }}
                placeholder={placeholder}
                value={priceValue}
                readOnly={readOnly}
            />
        </div>
    )
}

export default Input;