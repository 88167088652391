interface PropsType {
    token: string;
    className?: string;
}

const CurrencyView = ({ token, className }: PropsType) => {
    return (
        <div className={`${className} absolute h-8 rounded-full bg-[#293249] right-6 flex items-center pl-1 pr-4 cursor-default`}>
            <img alt="ETH" className="rounded-full w-6 h-6" src={token === "SNIP" ? "/assets/snip.svg" : "/assets/shimmer.png"} />
            <p className="text-white text-2xl ml-2">{token}</p>
        </div>
    )
}

export default CurrencyView;