import { useEffect, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { parseEther } from 'ethers/lib/utils.js';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useSnipTransfer } from 'hooks/useSnipBalance';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from  'react-loader-spinner'
import CurrencyView from 'components/currencyView';
import { SNIP_ADDRESS, err_msg } from 'constant';
import { SMRAddressChecksum } from 'utils';
import Input from "components/Input";
import Button from 'components/Button';

const SwapSection = () => {
    const [snipValue, setSnipValue] = useState<string>("");
    const { isConnected, address } = useAccount();
    const [isSufficient, setSufficient] = useState<boolean>(true);
    const [recipient, setRecipient] = useState<string>("");
    const [isValidSwap, setValidSwap] = useState<boolean>(true);
	const { executeRecaptcha } = useGoogleReCaptcha()
    const { data: snip_balance } = useBalance({
        address: address,
        token: SNIP_ADDRESS,
        watch: true
    });
    const [isLoading ,setLoading] = useState<boolean>(false);
    const { sendSnip }: any = useSnipTransfer({to: process.env.REACT_APP_ADMIN_WALLET!, SnipAmount: parseEther(snipValue || "0")});

    useEffect(() => {
        if ( Number(snip_balance?.formatted.toString()) >= Number(snipValue || "0") && (Number(snipValue) * 739854 / 10000000000) >= 0.1) {
            setSufficient(true)
        }
        else setSufficient(false)
    },[snip_balance, snipValue, isSufficient])

    const onHandleSwap = async () => {
        
        if (recipient === "" || !SMRAddressChecksum(recipient)) {
            setValidSwap(false);
            return;
        }
        if (executeRecaptcha) {
            await executeRecaptcha('mint');
        }
        setLoading(true);
        try {
            const tx = await sendSnip();
            await tx.wait(1);

            const res = await fetch(process.env.REACT_APP_API_URL!, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    transactionHash: tx.hash,
                    address: recipient
                })
            });

            if (res.status !== 200) throw new Error('You can claim if you want, by clicking on discord button.');

            toast.success("Transaction completed successfully!", {
                autoClose: 5000,
            });
            setLoading(false);
        }
        catch(err: any) {
            console.log(err);
            toast.error(err.toString(), {
                autoClose: err.toString() === err_msg ? false : 5000
            });
            setLoading(false);
        }
    }

    const onHandleRecipient = (e: any) => {
        if (e.target.value === "") setValidSwap(false);
        else {setValidSwap(true);}
        setRecipient(e.target.value);
    }

    const onHandleMax = () => {
        setSnipValue((snip_balance?.formatted!).toString());
    }

    const onSnipChange = (e: any) => {
        const val = e.target.value;
        const regex= /^(-?[1-9]\d*)((\.(\d*)?)?)$/i;

        if (((regex.test(val)) || val === "")) {
            setSnipValue(e.target.value)
        }
    }
    
    return (
        <div className="rounded-xl border border-gray-700 h-fit mt-20 max-w-[480px] mx-auto bg-[#0d111c] p-2 pt-3 relative">
            <h1 className="mb-4 ml-4 text-white text-xl">Swap SNIP To SMR</h1>
            <Input
                className="w-full mb-1"
                inputClassName="pr-32"
                onChange={(e) => onSnipChange(e)}
                priceValue={snipValue}
            />
            <CurrencyView className="top-[74px]" token='SNIP'/>

            {isConnected && 
                <div className='absolute top-[116px] right-[32px] text-base'>
                    <span className='text-[#98A1C0]'>Balance: {snip_balance?.formatted}</span>
                    {Number(snip_balance?.formatted.toString()) !== Number(snipValue || "0") &&
                        <button onClick={onHandleMax} className='text-[#4C82FB] font-bold ml-2 hover:opacity-80 transition-all'>MAX</button>
                    }
                </div>
            }

            <div className='w-10 h-10 flex items-center justify-center absolute bg-[#293249] border-4 border-[#0d111c] top-[136px] left-[calc(50%-18px)] rounded-xl z-10'>
                <span className='text-white text-2xl cursor-default'>&#8595;</span>
            </div>
            <Input
                className="w-full mb-8"
                inputClassName="pr-32"
                readOnly
                priceValue={(Number(snipValue) * 739854 / 10000000000).toString()}
            />
            <CurrencyView className="top-[176px]" token='SMR'/>

            <h1 className='text-gray-300 tracking-wide text-lg'>Recipient Wallet Address</h1>

            <Input
                onChange={onHandleRecipient}
                className={`${isValidSwap ? "" : "!border-red-500"} w-full mt-1 !h-12 !p-2 !rounded-md`}
                placeholder='Enter the SHIMMER payout address'
                inputClassName="text-xl placeholder:text-lg"
            />

            {!isValidSwap &&
                <p className='text-base text-red-500'>Invalid Shimmer Address</p>
            }

            {!isConnected ?
                <div className='mt-2 w-full flex justify-center items-center'>
                    <ConnectButton label="Connect"/>
                </div>
            :

            <Button
                onClick={onHandleSwap}
                active={snipValue !== "" ? isSufficient ? isLoading ? false : true : false : false}
                className='w-full h-14 mt-4 text-[#98A1C0] flex justify-center items-center'
            >
                {snipValue === "" ? "Enter an Amount" : isSufficient && isLoading ?  
                    <ThreeDots
                    height="80" 
                    width="80" 
                    radius="9"
                    color="#FFFFFF" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    visible={true}
                /> : isSufficient && !isLoading ? "Swap" : Number(snipValue || "0") < 1352 ? "Min = 1352 SNIP" : "Insufficient Balance"

                }
            </Button>
            }

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <ToastContainer />
        </div>
    )
}

export default SwapSection;