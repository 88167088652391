import Modal from 'react-modal';
import { Link } from 'react-router-dom';

interface ModalProps {
    isModal: boolean;
    closeModal?: () => void;
}

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: "100",
    },
};

Modal.setAppElement('body');
const AlertModal = ({
    isModal=false,
    closeModal,
}: ModalProps) => {
    return (
        <Modal
            isOpen={isModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            
        >
            <div className='flex flex-col items-center py-4 px-4'>
                <p className='text-white text-lg'>For any claim or complain, send the transaction id and shimmer mainnet address to our discord channel.</p>

                <Link to="https://discord.com/channels/950754221031034921/1111290172391313579" target="_blank" className='w-fit flex gap-x-2 items-center mt-8'>
                    <img alt='discord' src='/assets/discord.svg' className='w-10'/>
                    <p className='text-white'>Join our Discord</p>
                </Link>
            </div>
    </Modal>
    );
}

export default AlertModal;