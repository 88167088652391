import { useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
import { emojiAvatarForAddress } from 'utils/emojiAvatarForAddress';
import { shortenAddress } from 'utils';
import { FaChevronDown } from 'react-icons/fa';
import AlertModal from "components/Modal"

const Header = () => {
  const { isConnected, address } = useAccount();
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);

  const { color: backgroundColor, emoji } = useMemo(
    () => emojiAvatarForAddress(address as string),
    [address]
  );

  return (
    <header>
        <div className="h-20 fixed flex items-center justify-between w-full min-h-20 px-5 md:px-24 z-50 bg-[rgba(0, 0, 0, 0.3)] border-b border-[rgba(255,255,255,0.1)] backdrop-blur-md top-0 left-0">
            <Link to='/'>
                <img src={"/assets/images/logo.png"} width={150} height={50} alt={''} />
            </Link>
            <img alt='discord' className='w-10 animate-bounce cursor-pointer' src="/assets/discord.svg" onClick={() => setOpenModal(prev => !prev)}/>
            <div className='flex items-center'>
              <div className='flex flex-row items-center gap-2'>
                {isConnected ? (
                  <div className='relative'>
                    <div
                      className='flex flex-row items-center gap-2 px-5 py-2 font-bold text-white bg-opacity-50 rounded-full cursor-pointer bg-slate-700 hover:bg-white hover:text-slate-700 text-md'
                      onClick={() => setDropDownOpen(prev => !prev)}
                    >
                      <div
                        className='rounded-full'
                        style={{ backgroundColor: backgroundColor }}
                      >
                        {emoji}
                      </div>
                      <span>{address && shortenAddress(address)}</span>
                      <FaChevronDown />
                    </div>
                    {dropDownOpen && (
                      <>
                        <div
                          className='fixed top-0 left-0 z-10 w-screen h-screen'
                          onClick={() => setDropDownOpen(false)}
                        ></div>
                        <div className='absolute top-full right-0 w-[300px] flex flex-col items-center gap-2 p-2 bg-slate-800 border border-slate-700 rounded-sm translate-y-3 connect-button__container z-50'>
                          <ConnectButton />
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <ConnectButton label='Connect' />
                )}
              </div>
            </div>
        </div>

        <AlertModal isModal={isOpenModal} closeModal={() => setOpenModal(false)}/>
    </header>
  );
};

export default Header;
