interface PropsType {
    className?: string;
    children: React.ReactNode;
    active: boolean;
    onClick?: () => void;
}

const Button = ({
    className,
    children,
    active,
    onClick
}: PropsType) => {
    return (
        <button
            disabled={!active}
            className={`${className} ${active ? "bg-[#4C82FB] hover:bg-[#3371FA] active:bg-[#1A5FFA] text-white" : "bg-[#293249] text-[#98A1C0]"} text-2xl font-bold rounded-2xl transition-all`}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default Button;